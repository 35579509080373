import React, {useState} from "react"
import { Link } from "gatsby"
import Autolinker from 'autolinker';
import Layout from "../components/layout"
import Carousel from "../components/carousel"
import ImageRow from "../components/image-row"
import Break from "../components/break"
import Titles from "../components/titles"
import SEO from "../components/seo"
import Showcase from "../components/showcase"
import dateFormat from 'dateformat';

var showcaseActive = false;
const renderArtists = (artists) => {
  return "";
  let artist_rows = artists.map((artist) => <h3><Link to={`/${artist.slug}`}>{artist.title}</Link></h3> )
  return artist_rows

}

const artwork_row = (artworks, currentArtist) => {
      let artwork_filtered = artworks.filter((artwork) => {
        if(currentArtist) {
          if(artwork.artist[0].title == currentArtist) {
            return true
          }
          return false
        }
        return true;
      } )

    if(artwork_filtered.length > 0) {
    let artwork_rows = artwork_filtered.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index/3)

    if(!resultArray[chunkIndex]) {
    resultArray[chunkIndex] = [] // start a new chunk
    }

    resultArray[chunkIndex].push({
    type:"image",
    link:"/" + item.slug + "/exhibition",
    url:item.imageThumbnail?.[0]?.medium ?? item.images?.[0]?.medium ,
    details:<div className={item.sold}><div className="title">{item.artist[0].title}</div><div className="materials">{item.title}</div><div className="dimensions">{item.materials}
    </div></div>
    })

    return resultArray
  }, []).map((row) => <ImageRow forceLength={3    } images={row} /> )

    return artwork_rows;

    }
    else {
      return <div style={{textAlign:"center"}}>Check back later for artworks available at this exhibition</div>
    }
}


const renderButton = (cv) => {
  if(Array.isArray(cv) && cv[0] && cv[0].filename) {
  return cv.map((c) => <Link to={"https://franceskeevil.frb.io/catalogue/" + cv[0].filename}><h3>Download catalogue (pdf)</h3></Link>)
  }
}



const artworkListings = (pageContext,currentArtist,setCurrentArtist) => {
  if(pageContext.exhibition.artworks && pageContext.exhibition.artworks.length > 0) {
  return <>{artwork_row(pageContext.exhibition.artworks, currentArtist)}</>
}
}

const getLocation = ({location, locationUrl})=> {
  console.log({location, locationUrl})
    if(locationUrl) {
      return <Link to={locationUrl}>{location}</Link>
    }
    return location
}

const IndexPage = ({pageContext}) => {

  const [currentArtist, setCurrentArtist] = useState(undefined);
  const artists = pageContext.exhibition.artworks ?
  pageContext.exhibition?.artworks.map((work) => work.artist?.[0]).filter((thing, index, self) =>
  index === self.findIndex((t) => (
    t.id === thing.id
  ))) : [];

  if(pageContext.exhibition) {
  return (<div>
  <Layout hero={<Carousel images={[pageContext.exhibition.images[0].url]} title={pageContext.exhibition.title}/>}>
    <SEO title={pageContext.exhibition.title} description={"Exhibition presented by Frances Keevil"} />
    <h1 style={{textAlign:"center", fontSize:"36px", marginTop:"0px",marginBottom:"40px"}}>{pageContext.exhibition.title}</h1>
    <div>


      <ImageRow rowType={"largeRow"} images={[{type:"text", text: <div><p style={{padding:"36px 20px", fontSize:"18px", whiteSpace: "pre-wrap", textAlign:"center" }}>{dateFormat(pageContext.exhibition.date, "fullDate")} - {dateFormat(pageContext.exhibition.endDate, "fullDate")}<br /><br />{getLocation(pageContext.exhibition)}<br /><br /> <div dangerouslySetInnerHTML={{ __html: Autolinker.link(pageContext.exhibition.pageText)}} /></p></div>, span:"12"}] } />
      <div className={"titles inline"}>{renderArtists(artists)}{renderButton(pageContext.exhibition.catalogue)}</div>
    {artworkListings(pageContext,currentArtist,setCurrentArtist)}




  </div>
  </Layout>
</div>)
}
return <div></div>
}

export default IndexPage
